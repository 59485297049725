<template>
  <div id="FlightDelayReport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Monthly Report</div>
      </div>
    </div>
    <div id="filltertReport" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Filter</div>
      </div>

      <div class="box-S4">
        <div class="B-carddetail flex-between-center">
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="listyear"
              v-model="picker"
              label="year"
              return-object
              @change="Getsummary()"
            ></v-select>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListCustomer"
              v-model="selectowner"
              item-text="o_name"
              item-value="o_id"
              label="Customer"
              return-object
              @change="Getsummary()"
            ></v-select>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListDeparture"
              v-model="selectDeparture"
              item-text="ap_name"
              item-value="ap_id"
              label="Departure From"
              return-object
              @change="departureFromChange()"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="summarydata">
          <template v-slot:item.this_month="{ item }" class="text-xs-right">
            <div class="b-action">
              <v-btn
                class="theme-btn-even"
                @click="createpdf(item.this_month, item.monthname)"
              >
                <span>VIEW</span>
                <span class="I-arrrowR"></span>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";
import MonthlyReport from "../utils/MonthlyReport";
import moment from "moment";
export default {
  data: () => ({
    headers: [
      { value: "monthname", text: "Month", sortable: true },
      { value: "totalflight", text: "Total Flight", sortable: true },
      { value: "revenue", text: "Revenue Time", sortable: true },
      { value: "non_revenue", text: "Non Revenue Time", sortable: true },
      // { value: "ac_seat_layout_url", text: "Seat Layout", sortable: true },
      { value: "pax_out", text: "Pax Out", sortable: true },
      { value: "pax_in", text: "Pax In", sortable: true },
      { value: "this_month", text: "view", sortable: false },
    ],
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    picker: 0,
    dateSelect: new Date().toISOString().substr(0, 4),
    menu: false,
    // itemsListCompany: [],
    //Edit
    itemsListCustomer: [],
    // selectcompany:"",
    selectowner: "",
    ArrListDelayReport: [],
    DataDelayReport: [],
    //Add
    userdata: null,
    listyear: [],
    summarydata: [],
    monthname: "",
    itemsListDeparture: [],
    selectDeparture: {},
  }),
  computed: {},
  async mounted() {
    //Add
    await this.renderListDeparture();
    this.userdata = this.$CurrentUser.GetCurrentUser();
    console.log("this.userdata",this.userdata)
    // this.SelectListCompany();
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    this.listyear = range(currentYear, currentYear - 10, -1);
    this.picker = this.listyear[0];
    console.log(this.listyear);
    await this.SelectListOwner();
    //this.SelectListPassengerSemi_Manifest(this.picker, this.selectowner.o_id);
    await this.Getsummary();
    //await this.$MonthlyReport.getdata();
  },

  methods: {
    async SelectListOwner() {
      try {
        const q = {};
        //Add
       
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data.filter(x=>x.o_id != 1);
        this.selectowner = {
          o_id: this.itemsListCustomer[0].o_id,
          o_name: this.itemsListCustomer[0].o_name,
        };

        // res.data.forEach(element => {
        //   this.itemsListCompany.push(element.c_name);
        // });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    DaysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    async createpdf(month, monthname) {
      var aaaa = await this.$MonthlyReport.getPdf(
        month,
        this.picker,
        this.selectowner.o_id,
        monthname,
        this.selectDeparture.ap_id,
        this.userdata.FullName
      );
      console.log(aaaa);
       },
    async Getsummary() {
      const q = {};
      q.year = this.picker;
      if (this.userdata.r_id == "2") q.customerid = this.userdata.o_id;
      else q.customerid = this.selectowner.o_id;
      q.ap_id = this.selectDeparture.ap_id
      var res = await feathersClientUOA
        .service("summarymonthly")
        .find({ query: q });
      console.log("res");
      console.log(res);
       console.log(this.userdata)
      this.summarydata = res;
    },
    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      await this.selectDepartureFrom();
    },
    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },
    async departureFromChange(arg) {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
      await this.Getsummary();
    },
  },
};
</script> 


<style>
</style>